.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.tab-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-list {
  display: flex;
  padding-left: 0;
  margin-top: 2px;
}

.tab-scroll::-webkit-scrollbar {
  display: none;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  white-space:nowrap;
  margin-bottom: -2px;
  padding: 0.5rem 0.75rem;
  border: solid #aaa;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-width: 0 0 1px 0;
}

.tab-list-active {
  background-color: white;
  border: solid #aaa;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-width: 1px 1px 0 1px;
  margin-bottom: -2px;
}

.catalog {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
  overflow-x: auto;
}

content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: blue;
}

main {
  /* Чтобы занимал оставшееся пространство */
  display: flex;
  flex: 1;
  flex-grow: 1;
  background: lightgray;
}

.vertical_main {
  /* Чтобы занимал оставшееся пространство */
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  background: lightgray;
}

footer {
  /* Чтобы footer не уменьшался */
  flex-shrink: 0;
  background: white;
}

svg {
  display: flex;
  flex: 1;
  background: gray;
}

.small {
  font: 5px sans-serif;
}

label,select,button{
  display:flex;
  flex-direction:column;
  margin: 1em
}

select.colorSelect option.red
{
    background-color: #ff0000;
}
select.colorSelect option.white
{
    background-color: #ffffff;
}
select.colorSelect option.black
{
    background-color: #000000;
    color: white;
}
select.colorSelect option.blue
{
    background-color: #0000ff;
}
select.colorSelect option.lightblue
{
    background-color: #007fff;
}
select.colorSelect option.green
{
    background-color: #00ff00;
}
select.colorSelect option.darkgreen
{
    background-color: #007f00;
}
select.colorSelect option.brown
{
    background-color: #8b4513;
}
select.colorSelect option.yellow
{
    background-color: #ffff00;
}

input[type=radio]{display:none;}

input[type=radio] + label.wire{
  display:inline-block;
  cursor: pointer;
  outline: 0;
  width: 48px;
  height: 48px;
  margin: 1px;
  background-image: url(./assets/images/baseline_timeline_black.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size:     cover; 
  text-indent: -99999px;
}

input[type=radio]:checked + label.wire{background-image: url(./assets/images/baseline_timeline_white.png);}

input[type=radio] + label.busbar{
display:inline-block;
cursor: pointer;
outline: 0;
width: 48px;
height: 48px;
margin: 1px;
background-image: url(./assets/images/sharp_straighten_black.png);
background-repeat: no-repeat;
background-position: 0 0;
background-size:     cover; 
text-indent: -99999px;
}

input[type=radio]:checked + label.busbar{background-image: url(./assets/images/sharp_straighten_white.png);}

input[type=radio] + label.editconnector{
  display:inline-block;
  cursor: pointer;
  outline: 0;
  width: 48px;
  height: 48px;
  margin: 1px;
  background-image: url(./assets/images/sharp_insights_black.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size:     cover; 
  text-indent: -99999px;
  }
  
  input[type=radio]:checked + label.editconnector{background-image: url(./assets/images/sharp_insights_white.png);}
  
  .popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);  
  width: max-content;
  height: fit-content;
  margin: auto;
  background: white;
}